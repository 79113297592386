import { Col, Row } from "reactstrap";
import year from "../utils/currentYear.js";

const Footer = () => {
  return (
    <>
      <Row className="site-footer">
        <Col>
          <a href="tel:7604158620">
            <i className="fa fa-phone"></i> 7604158620
          </a>
        </Col>
        <Col xs="12" sm="4" className="text-sm-center">
          <a href="mailto:info@bodytherapyarts.com">
            <i className="fa fa-envelope"></i> info@bodytherapyarts.com
          </a>
        </Col>
        <Col xs="12" sm="4" className="text-sm-center">
          <a
            href="https://www.google.com/maps/dir//1991+Village+Park+Way,+Encinitas,+CA+92024/@33.053135,-117.3280127,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x80dc0b95bd8b1c0f:0xd2d309cdd42fa7b!2m2!1d-117.2456115!2d33.0531621?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-map" /> 1991 Village Park Way, Encinitas, CA
            92024
          </a>
        </Col>
        <Col xs="12" className="text-center">
          <div className="copyright">
            Copyright &#x000A9;&nbsp;{year}&nbsp;All Rights Reserved |&nbsp;Body
            Therapy Arts
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
