import { useEffect, useState, useRef, useCallback } from 'react';
import {
  APIProvider,
  Map,
  useMap,
  AdvancedMarker,
  MapCameraChangedEvent,
  Pin,
  // InfoWindow
} from '@vis.gl/react-google-maps';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import type { Marker } from '@googlemaps/markerclusterer';
import { Circle } from './circle';


type Poi = { key: string, location: google.maps.LatLngLiteral };

const locations: Poi[] = [
  { key: 'bodyTherapyArts', location: { lat: 33.0531, lng: -117.2456 } },
];

const getApiKey = (): string => {
  const hostname = window.location.hostname;
  let key: string | undefined;


  if (hostname === 'localhost') {
    key = import.meta.env.VITE_MAPS_KEY;
  } else if (hostname === 'bodytherapyarts.com' || hostname.includes('bodytherapyarts.com')) {
    key = import.meta.env.VITE_CUSTOM_DOMAIN_MAPS_KEY;
  } else {
    console.error('Unexpected hostname:', hostname);
    key = '';
  }

  if (!key) {
    console.error(`No API key found for hostname: ${hostname}`);
  }

  return key || '';
};

const MapComponent: React.FC = () => {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleApiError = (e: ErrorEvent) => {
      console.error('Maps API failed to load:', e);
      setError(`Failed to load Maps API: ${e.message}`);
    };

    window.addEventListener('error', handleApiError);

    return () => {
      console.log('Environment variables:', import.meta.env);
      window.removeEventListener('error', handleApiError);
    };
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <APIProvider apiKey={getApiKey()}>
      <Map
        defaultZoom={13}
        defaultCenter={{ lat: 33.0531, lng: -117.2456 }}
        onCameraChanged={(ev: MapCameraChangedEvent) =>
          console.log('camera changed:', ev.detail.center, 'zoom:', ev.detail.zoom)
        }
        mapId='da37f3254c6a6d1c'
      >
        <PoiMarkers pois={locations} />
         {/* <InfoWindow position={{ lat: 33.0531, lng: -117.2456 }}>
        Hello World!
      </InfoWindow> */}
      </Map>
    </APIProvider>
  );
};

const PoiMarkers = (props: { pois: Poi[] }) => {
  const map = useMap();
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);
  const [circleCenter, setCircleCenter] = useState<google.maps.LatLng | null>(null);

  const handleClick = useCallback((ev: google.maps.MapMouseEvent) => {
    if (!map) return;
    if (!ev.latLng) return;

    console.log('marker clicked: ', ev.latLng.toString());
    map.panTo(ev.latLng);
    setCircleCenter(ev.latLng);
  }, [map]);

  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers(prev => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  return (
    <>
      <Circle
        radius={800}
        center={circleCenter}
        strokeColor={'#0c4cb3'}
        strokeOpacity={1}
        strokeWeight={3}
        fillColor={'#3b82f6'}
        fillOpacity={0.3}
      />
      {props.pois.map((poi: Poi) => (
        <AdvancedMarker
          key={poi.key}
          position={poi.location}
          ref={marker => setMarkerRef(marker, poi.key)}
          clickable={true}
          onClick={handleClick}
        >
          <Pin background={'#ffb69e'} glyphColor={'#000'} borderColor={'#000'} />
        </AdvancedMarker>
      ))}
    </>
  );
};

export default MapComponent;